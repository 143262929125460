import React from 'react';
import { Row, Col } from 'antd';
import { Helmet } from 'react-helmet';
import Logo from '../../assets/icons/logo.png';
import BaseButton from '../../components/generic/buttons/BaseButton';
import { ShareContainer, ShareCard, LogoSection, LogoImg } from './ShareStyles';

const REACT_WEB_URL = process.env.REACT_APP_BO_URL;
const REACT_APP_LINK = process.env.REACT_APP_LINK;
const REACT_APP_ANDROID = process.env.REACT_APP_ANDROID;
const REACT_APP_IOS = process.env.REACT_APP_IOS;

const SharePage = ({ match: { params } }) => {
  const agent = navigator.userAgent.toLowerCase();
  window.location = REACT_APP_LINK + 'event/' + params.id;
console.log(REACT_APP_LINK + 'event/' + params.id)
  const store =
    agent.indexOf('android') > -1 ? REACT_APP_ANDROID : REACT_APP_IOS;

  // setTimeout(() => window.open(store, '_blank'), 1000);

  return (
    <ShareContainer>
      <Helmet>
        <title>Share STARTPOINT Application</title>
        <link rel="canonical" href={REACT_WEB_URL} />
        <meta
          name="description"
          content="I would like to share some content of the STARTPOINT"
        />
      </Helmet>
      <ShareCard>
        <LogoSection>
          <LogoImg src={Logo} alt="logo" />
        </LogoSection>
        <Row>
          <Col xs={24} style={{ textAlign: 'center' }}>
            <BaseButton
              type="primary"
              align="initial"
              text="Open Application"
              onClick={() =>
                window.open(
                  REACT_APP_LINK + 'event/' + params.id,
                  '_blank'
                )
              }
            />
          </Col>
          <Col xs={24} style={{ marginTop: '20px', textAlign: 'center' }}>
            <BaseButton
              type="default"
              align="initial"
              text="Download Application"
              onClick={() => window.open(store, '_blank')}
            />
          </Col>
        </Row>
      </ShareCard>
    </ShareContainer>
  );
};

export default SharePage;
