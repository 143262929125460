import React, { Component } from "react";
import { Field, reduxForm, initialize } from "redux-form";
import { Row, Col, notification } from "antd";
import { withLocalize } from "react-localize-redux";
import { GetInfo, UpdateInfo } from "../../infra/requests/EventInfoRequests";
import ImageInput from "../../components/generic/inputs/ImageInput";
import {
  FormContainer,
  BaseForm,
  SpinLoading,
  Separator,
} from "../../styles/BasicStyles";
import FormValidator from "../../infra/services/validations/FormValidator";
import ManageFormHeader from "../../components/base/ManageFormHeader";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { CloseMenu } from "../../redux/Menu/menu.actions";
import DateInput from "../../components/generic/inputs/DateInput";
import SwitchInput from "../../components/generic/inputs/SwitchInput";
import InteractiveMapComponent from "../../components/generic/map/InteractiveMapComponent";

const validations = FormValidator.make({
  date_1: "required",
  date_2: "required",
  date_3: "required",
  date_4: "required",
  date_5: "required",
});

class ManageEventInfoPage extends Component {
  state = {
    loading: true,
  };

  componentDidMount = async () => {
    const { dispatch, CloseMenu } = this.props;

    CloseMenu();

    const result = await GetInfo(process.env.REACT_APP_EVENT_INFO_ID);
    console.log(result);
    dispatch(
      initialize("manage_event_info_form", {
        ...result.data,
        date_1: result.data.dates[0],
        date_2: result.data.dates[1],
        date_3: result.data.dates[2],
        date_4: result.data.dates[3],
        date_5: result.data.dates[4],
        rows: 26,
        columns: 50,
      })
    );

    this.setState({
      loading: false,
    });
  };

  onSubmit = async (values) => {
    try {
      const { dispatch } = this.props;
      this.setState({ loading: true });

      if (values.image && !values.image.blob) {
        delete values.image;
      }

      const { data, success } = await UpdateInfo(
        process.env.REACT_APP_EVENT_INFO_ID,
        values
      );

      if (success) {
        dispatch(
          initialize("manage_event_info_form", {
            ...data,
            date_1: data.dates[0],
            date_2: data.dates[1],
            date_3: data.dates[2],
            date_4: data.dates[3],
            date_5: data.dates[4],
            rows: 26,
            columns: 50,
          })
        );

        notification.success({
          message: "Registo alterado com sucesso!",
        });
      }

      return this.setState({
        loading: false,
      });
    } catch (e) {
      console.error(e);
      this.setState({
        loading: false,
      });
    }
  };

  render() {
    const { handleSubmit, pristine } = this.props;
    const { loading } = this.state;

    if (loading) return <SpinLoading />;

    return (
      <React.Fragment>
        <ManageFormHeader
          titles={["Informação do Evento", "Editar"]}
          buttons={[
            {
              type: "primary",
              icon: "save",
              text: "Gravar",
              onClick: handleSubmit((values) => this.onSubmit(values)),
              disabled: pristine,
            },
          ]}
        />
        <FormContainer>
          <BaseForm onSubmit={handleSubmit(this.onSubmit)}>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name="date_1"
                  label="1ª Data do Evento *"
                  placeholder="Selecione a 1ª data do evento"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name="date_2"
                  label="2ª Data do Evento *"
                  placeholder="Selecione a 2ª data do evento"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name="date_3"
                  label="3ª Data do Evento *"
                  placeholder="Selecione a 3ª data do evento"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name="date_4"
                  label="4ª Data do Evento *"
                  placeholder="Selecione a 4ª data do evento"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={DateInput}
                  name="date_5"
                  label="5ª Data do Evento *"
                  placeholder="Selecione a 5ª data do evento"
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={SwitchInput}
                  name={`event_available`}
                  label={"Mostrar evento ativo na aplicação?"}
                  inlineCheckbox={true}
                />
              </Col>
            </Row>
            <Separator />
            <Row gutter={24}>
              <Col xs={12}>
                <Field
                  component={ImageInput}
                  name="image"
                  label="Mapa do Evento"
                  ratio={0.8}
                  noCrop={true}
                />
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <InteractiveMapComponent label="Mapa do Evento" />
              </Col>
            </Row>
          </BaseForm>
        </FormContainer>
      </React.Fragment>
    );
  }
}

ManageEventInfoPage = reduxForm({
  form: "manage_event_info_form",
  validate: validations,
})(ManageEventInfoPage);

const mapActionToProps = (dispatch) =>
  bindActionCreators({ CloseMenu }, dispatch);

export default withLocalize(
  connect(null, mapActionToProps)(ManageEventInfoPage)
);
