import styled from 'styled-components';
import { Tabs } from 'antd';
const TabPane = Tabs.TabPane;

export const LeaderBoardContainer = styled.div`
  background-color: #F7F7FC;
  height: 100vh;
`;

export const LeaderBoardHeader = styled.div`
  width: 100%;
  height: 155px;
  background: linear-gradient(141.87deg, #F24662 -4.75%, #362B70 88.13%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LeaderBoardTitle = styled.h1`
  font-size: 50px;
  color: #ffffff;
  margin-bottom: 0px;
`;

export const LeaderBoardContent = styled.div`
  margin: 89px 161px;
`;

export const TableHeader = styled.div`
  width: 100%;
  margin-bottom: 12px;
  font-size: 17px;
  line-height: 23px;
  color: #7C7C7C;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
`;

export const TableFirstColumn = styled.div`
  width: 142px;
  margin-left: 55px;
  margin-right: 13px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableMiddleColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TableLastColumn = styled.div`
  width: 143px;
  margin-right: 55px;
  margin-left: 13px;
`;

export const TableLine = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  margin-bottom: 5px;
  background-color: #ffffff;
  align-items: center;
`;

export const Number = styled.div`
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  color: #7C7C7C;
`;

export const ImageRanking = styled.img`
  width: 34px;
  height: 34px;
  margin-right: 24px;
`;

export const ImageRankingEmpty = styled.div`
  width: 34px;
  height: 34px;
  margin-right: 24px;
`;

export const UserImage = styled.img`
  width: 56px;
  height: 56px;
  margin-right: 18px;
  border-radius: 50%;
`;

export const UserName = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  color: #000000;
`;

export const UserPoints = styled.div`
  font-size: 20px;
  font-weight: 400;
  line-height: 27px;
  color: #362B70;
`;